import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

import icon_grado_a from '../assets/img/grados/colibri.svg';
import icon_grado_b from '../assets/img/grados/serpiente.svg';
import icon_grado_c from '../assets/img/grados/jaguar.svg';
import icon_grado_d from '../assets/img/grados/tecolote.svg';
import icon_grado_e from '../assets/img/grados/venado.svg';
import icon_grado_f from '../assets/img/grados/escorpion.svg';
import icon_grado_g from '../assets/img/grados/aguila.svg';

import anim_a from '../assets/img/grados/anim1.jpeg';
import anim_b from '../assets/img/grados/anim2.jpeg';
import anim_c from '../assets/img/grados/anim3.jpeg';
import anim_d from '../assets/img/grados/anim4.jpeg';
import anim_e from '../assets/img/grados/anim5.jpeg';
import anim_f from '../assets/img/grados/anim6.jpeg';
import anim_g from '../assets/img/grados/anim7.jpeg';

import anim_a_icon from '../assets/img/grados/anim_icon_1.png';
import anim_b_icon from '../assets/img/grados/anim_icon_2.png';
import anim_c_icon from '../assets/img/grados/anim_icon_3.png';
import anim_d_icon from '../assets/img/grados/anim_icon_4.png';
import anim_e_icon from '../assets/img/grados/anim_icon_5.png';
import anim_f_icon from '../assets/img/grados/anim_icon_6.png';
import anim_g_icon from '../assets/img/grados/anim_icon_7.png';

export const Grados = () => {
    return (
        <>
        <header className="headerYaoWrapp head_back_b">
            <div className="container">
                <div className="yao_heading">Grados</div>
            </div>
        </header>


        <section id="grados" className="mt-5">
          <div className="container">
            <div className="row">

            <Tabs
            defaultActiveKey="colibri"
            id="grado-tab"
            className="tabs_grados mb-3"
            fill
            >
            <Tab eventKey="colibri" title={<span>Colibrí <img src={icon_grado_a} alt="colibri" className="icon_animal" /> </span>}>
               
            <div className="row">
                <div className="col-lg-6">
                    <img src={anim_a} alt="anim_a" className='img-fluid mx-auto d-block mt-5' />
                </div>

                <div className="col-lg-6">

                    <Card className="mt-5">
                        <Card.Body>
                            
                        
                            <h2 
                            className="mt-2">
                            Animal de Poder
                            </h2>
                            <p className="mb-4 title_sub_anim">Huitzilin -<span> Colibrí</span><img src={anim_a_icon} alt="anim_a_icon" className='img-fluid' /></p>

                            <h2 
                            className="mt-2">
                            Trabajo Físico
                            </h2>
                            <p className="mb-4">Resistencia</p>

                            <h2 
                            className="mt-2">
                            Trabajo Mental
                            </h2>
                            <p className="mb-4">Voluntad</p>

                            <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Características</Accordion.Header>
                                    <Accordion.Body>
                                    <h2 
                                    className="mt-2">
                                Alimento
                                    </h2>
                                    <p className="mb-4">
                                        Miel de flores que obtiene a través de un pico largo, que adquiere la forma de falo, con el que poliniza las flores.
                                    </p>

                                    <h2 
                                    className="mt-2">
                                Arsenal Natural
                                    </h2>
                                    <p className="mb-4">
                                        Pico largo, alas cortas y veloces, resistencia para mantenerse suspendido en el aire, vuelo multidireccional, valor para enfrentar rivales más grandes, movimientos rápidos y precisos.
                                    </p>


                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>

                          
                        </Card.Body>
                    </Card>
                

                </div>
            </div>




            </Tab>
            <Tab eventKey="serpiente" title={<span>Serpiente <img src={icon_grado_b} alt="serpiente" className="icon_animal" /> </span>}>

            <div className="row">
                <div className="col-lg-6">
                    <img src={anim_b} alt="anim_b" className='img-fluid mx-auto d-block mt-5' />
                </div>

                <div className="col-lg-6">

                    <Card className="mt-5">
                        <Card.Body>
                           
                        
                            <h2 
                            className="mt-2">
                            Animal de Poder
                            </h2>
                            <p className="mb-4 title_sub_anim">Koatl -<span> Serpiente</span><img src={anim_b_icon} alt="anim_b_icon" className='img-fluid' /></p>

                            <h2 
                            className="mt-2">
                            Trabajo Físico
                            </h2>
                            <p className="mb-4">Energía</p>

                            <h2 
                            className="mt-2">
                            Trabajo Mental
                            </h2>
                            <p className="mb-4">Paciencia</p>

                            <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Características</Accordion.Header>
                                    <Accordion.Body>
                                    <h2 
                                    className="mt-2">
                                Alimento
                                    </h2>
                                    <p className="mb-4">
                                        Animales más pequeños que caza, escondida pacientemente, cerca del nido o madriguera de su presa.
                                    </p>

                                    <h2 
                                    className="mt-2">
                                Arsenal Natural
                                    </h2>
                                    <p className="mb-4">
                                    Visión termográfica, capacidad de hipnotizar con su sonido de cascabel, la lengua bífida siente la temperatura de su presa. Veneno letal en sus colmillos, anillos fuertes, movimientos veloces y certeros, capacidad de camuflaje.
                                    </p>


                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>



                          
                        </Card.Body>
                    </Card>
                

                </div>
            </div>


            </Tab>
            <Tab eventKey="jaguar" title={<span>Jaguar <img src={icon_grado_c} alt="jaguar" className="icon_animal" /> </span>}>

            <div className="row">
                <div className="col-lg-6">
                    <img src={anim_c} alt="anim_c" className='img-fluid mx-auto d-block mt-5' />
                </div>

                <div className="col-lg-6">

                    <Card className="mt-5">
                        <Card.Body>
                         
                        
                            <h2 
                            className="mt-2">
                            Animal de Poder
                            </h2>
                            <p className="mb-4 title_sub_anim">Ozelotl -<span> Jaguar</span><img src={anim_c_icon} alt="anim_c_icon" className='img-fluid' /></p>

                            <h2 
                            className="mt-2">
                            Trabajo Físico
                            </h2>
                            <p className="mb-4">Fuerza</p>

                            <h2 
                            className="mt-2">
                            Trabajo Mental
                            </h2>
                            <p className="mb-4">Cautela</p>

                            <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Características</Accordion.Header>
                                    <Accordion.Body>
                                    <h2 
                                    className="mt-2">
                                Alimento
                                    </h2>
                                    <p className="mb-4">
                                    Animales medianos que caza durante las noches pues posee visión nocturna.
                                    </p>

                                    <h2 
                                    className="mt-2">
                                Arsenal Natural
                                    </h2>
                                    <p className="mb-4">
                                    Garras retráctiles, colmillos grandes y mandíbulas poderosas. Puede nadar, trepar y andar por tierra. Olfato y oídos muy finos. Cautela, fuerza, habilidad y contundencia.
                                    </p>


                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>



                       
                        </Card.Body>
                    </Card>
                

                </div>
            </div>


            </Tab>
            <Tab eventKey="tecolote" title={<span>Tecolote <img src={icon_grado_d} alt="tecolote" className="icon_animal" /> </span>}>
              
            <div className="row">
                <div className="col-lg-6">
                    <img src={anim_d} alt="anim_d" className='img-fluid mx-auto d-block mt-5' />
                </div>

                <div className="col-lg-6">

                    <Card className="mt-5">
                        <Card.Body>
                           
                        
                            <h2 
                            className="mt-2">
                            Animal de Poder
                            </h2>
                            <p className="mb-4 title_sub_anim">Tecolotl -<span> Tecolote</span><img src={anim_d_icon} alt="anim_d_icon" className='img-fluid' /></p>

                            <h2 
                            className="mt-2">
                            Trabajo Físico
                            </h2>
                            <p className="mb-4">Contundencia</p>

                            <h2 
                            className="mt-2">
                            Trabajo Mental
                            </h2>
                            <p className="mb-4">Sigilo</p>

                            <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Características</Accordion.Header>
                                    <Accordion.Body>
                                    <h2 
                                    className="mt-2">
                                Alimento
                                    </h2>
                                    <p className="mb-4">
                                    Roedores y animales pequeños que adquiere durante las noches lejos del lugar en donde pasa el día, posee visión nocturna
                                    </p>

                                    <h2 
                                    className="mt-2">
                                Arsenal Natural
                                    </h2>
                                    <p className="mb-4">
                                    Alas con plumas que no emiten sonido durante el vuelo. Visión periférica, sensores auditivos que les permiten cazar en la oscuridad total, garras fuertes y filosas. Sigilo, velocidad y contundencia.
                                    </p>


                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>



                          
                        </Card.Body>
                    </Card>
                

                </div>
            </div>
            </Tab>
            
            <Tab eventKey="venado" title={<span>Venado <img src={icon_grado_e} alt="venado" className="icon_animal" /> </span>}>
              
            <div className="row">
                <div className="col-lg-6">
                    <img src={anim_e} alt="anim_e" className='img-fluid mx-auto d-block mt-5' />
                </div>

                <div className="col-lg-6">

                    <Card className="mt-5">
                        <Card.Body>
                         
                        
                            <h2 
                            className="mt-2">
                            Animal de Poder
                            </h2>
                            <p className="mb-4 title_sub_anim">Mazatl -<span> Venado</span><img src={anim_e_icon} alt="anim_e_icon" className='img-fluid' /></p>

                            <h2 
                            className="mt-2">
                            Trabajo Físico
                            </h2>
                            <p className="mb-4">Agilidad</p>

                            <h2 
                            className="mt-2">
                            Trabajo Mental
                            </h2>
                            <p className="mb-4">Fluidez</p>

                            <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Características</Accordion.Header>
                                    <Accordion.Body>
                                    <h2 
                                    className="mt-2">
                                Alimento
                                    </h2>
                                    <p className="mb-4">
                                    Hiervas y frutas que obtiene de praderas y bosques. Tienen que estar muy atentos porque pueden ser presa fácil para un depredador.
                                    </p>

                                    <h2 
                                    className="mt-2">
                                Arsenal Natural
                                    </h2>
                                    <p className="mb-4">
                                    Percepción sensorial muy fina. Grandes cornamentas. Un estado de alerta muy desarrollado. Habilidad, rapidez y agilidad.
                                    </p>


                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>



                         
                        </Card.Body>
                    </Card>
                

                </div>
            </div>



            </Tab>
            <Tab eventKey="escorpion" title={<span>Escorpión <img src={icon_grado_f} alt="escorpion" className="icon_animal" /> </span>}>

            <div className="row">
                <div className="col-lg-6">
                    <img src={anim_f} alt="anim_f" className='img-fluid mx-auto d-block mt-5' />
                </div>

                <div className="col-lg-6">

                    <Card className="mt-5">
                        <Card.Body>
                        
                        
                            <h2 
                            className="mt-2">
                            Animal de Poder
                            </h2>
                            <p className="mb-4 title_sub_anim">Kolotl -<span> Escorpión</span><img src={anim_f_icon} alt="anim_f_icon" className='img-fluid' /></p>

                            <h2 
                            className="mt-2">
                            Trabajo Físico
                            </h2>
                            <p className="mb-4">Infiltración</p>

                            <h2 
                            className="mt-2">
                            Trabajo Mental
                            </h2>
                            <p className="mb-4">Adaptación</p>

                            <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Características</Accordion.Header>
                                    <Accordion.Body>
                                    <h2 
                                    className="mt-2">
                                Alimento
                                    </h2>
                                    <p className="mb-4">
                                    Insectos y otros animales pequeños que cazan durante la noche.
                                    </p>

                                    <h2 
                                    className="mt-2">
                                Arsenal Natural
                                    </h2>
                                    <p className="mb-4">
                                    Visión nocturna y ultravioleta. Capacidad de adaptación milenaria. Fuertes pinzas y poderoso aguijón capaz de matar a un animal cientos de veces más grande que él. Fluorescencia para atraer a su presa e inyectarla, sigilo y letalidad.
                                    </p>


                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>



                        </Card.Body>
                    </Card>
                

                </div>
            </div>


            </Tab>

            <Tab eventKey="aguila" title={<span>Águila <img src={icon_grado_g} alt="aguila" className="icon_animal" /> </span>}>
             
            <div className="row">
                <div className="col-lg-6">
                    <img src={anim_g} alt="anim_g" className='img-fluid mx-auto d-block mt-5' />
                </div>

                <div className="col-lg-6">

                    <Card className="mt-5">
                        <Card.Body>
                           
                        
                            <h2 
                            className="mt-2">
                            Animal de Poder
                            </h2>
                            <p className="mb-4 title_sub_anim">Kuauhtli -<span> Águila</span><img src={anim_g_icon} alt="anim_g_icon" className='img-fluid' /></p>

                            <h2 
                            className="mt-2">
                            Trabajo Físico
                            </h2>
                            <p className="mb-4">Poder</p>

                            <h2 
                            className="mt-2">
                            Trabajo Mental
                            </h2>
                            <p className="mb-4">Visión</p>

                            <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Características</Accordion.Header>
                                    <Accordion.Body>
                                    <h2 
                                    className="mt-2">
                                Alimento
                                    </h2>
                                    <p className="mb-4">
                                    Aves más pequeñas, roedores y reptiles.
                                    </p>

                                    <h2 
                                    className="mt-2">
                                Arsenal Natural
                                    </h2>
                                    <p className="mb-4">
                                    Grandes alas que le permiten volar en lo más alto. Visión aguda. Garras filosas y poderosas.
                                    </p>


                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>



                           
                        </Card.Body>
                    </Card>
                

                </div>
            </div>


            </Tab>
    </Tabs>


            </div>
          </div>
        </section>


        </>
    )
}