import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons"
import { faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons"
import { faYoutube } from "@fortawesome/free-brands-svg-icons"

import yaoContA from '../assets/img/contact_img.png';
import { FormGroup } from 'react-bootstrap';
import { fetchSendContactForm } from '../config/fetchUtil';

export const Contacto = () => {
    const history = useNavigate();

    const [info, setInfo] = useState({
        office_phone: '',
        office_mail: '',
        store_address: '',
        store_phone: '',
        store_mail: ''
    });

    const [nombre, setNombre] = useState('')
    const [correo, setCorreo] = useState('')
    const [telefono, setTelefono] = useState('')
    const [texto, setMensaje] = useState('')

    useEffect(() => {

    }, [])

    const sendContactInfo = (event: any) => {
        event.preventDefault();
        let body = JSON.stringify({
            'name': nombre,
            'email': correo,
            'phone': telefono,
            'message': texto
        })

        // Send email api comes soon
        fetchSendContactForm(body).then(response => {
            console.log(response)
        }).catch(error => {
            console.log(error)
        })
    }

    return (
        <>
            <header className="headerYaoWrapp head_back_f">
                <div className="container">
                    <div className="yao_heading">Contacto</div>
                </div>
            </header>

            <section id="contact_yao" className="mt-5">
                <div className="container">

                    <div className="row">
                        <div className="col-lg-5">
                            <img src={yaoContA} alt="icon" className="img-fluid" />
                            <p>
                                Ya sea que tengas alguna duda o algo que decirnos, puedes escribirnos. Nosotros nos comunicaremos pronto contigo.
                            </p>

                            <ul className="list_contact">
                                <li> <FontAwesomeIcon icon={faMobileAlt} /> <a href="tel:5532693888">55-3269-3888</a></li>
                                <li> <FontAwesomeIcon icon={faEnvelopeOpenText} /> <a href="mailto:contacto@yaollin.com">contacto@yaollin.com</a></li>
                                <li> <FontAwesomeIcon icon={faYoutube} /> <a href="https://www.youtube.com/channel/UCa5VoFKL3gYcEUsKl1QpPFA" target="_blank">Yaollin Arte Marcial Mexihka-Tolteka</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-7">
                            <Form className="form_yao_c">
                                <FormGroup role="form">
                                    <FloatingLabel
                                        controlId="floatingInputa"
                                        label="Nombre"
                                        className="mb-2"
                                    >
                                        <Form.Control type="text" required onChange={e => setNombre(e.target.value)} placeholder="Nombre" />
                                    </FloatingLabel>

                                    <FloatingLabel
                                        controlId="floatingInputb"
                                        label="Correo"
                                        className="mb-2"
                                    >
                                        <Form.Control type="email" required onChange={e => setCorreo(e.target.value)} placeholder="Correo" />
                                    </FloatingLabel>

                                    <FloatingLabel
                                        controlId="floatingInputc"
                                        label="Teléfono"
                                        className="mb-2"
                                    >
                                        <Form.Control type="text" required onChange={e => setTelefono(e.target.value)} placeholder="Teléfono" />
                                    </FloatingLabel>

                                    <FloatingLabel controlId="floatingInputd" label="Mensaje">
                                        <Form.Control
                                            required onChange={e => setMensaje(e.target.value)}
                                            as="textarea"
                                            placeholder="Mensaje"
                                            style={{ height: '200px' }}
                                        />
                                    </FloatingLabel>

                                    <input type="submit" value="Enviar Mensaje" id="sendhome" onClick={sendContactInfo} className="mt-3 btn_submit_yao" />
                                </FormGroup>
                            </Form>
                        </div>

                    </div>

                </div>
            </section>

        </>
    )
}