import Card from 'react-bootstrap/Card';
import yaoImgM03 from '../assets/img/yaoImg11.png';


export const Objetivos = () => {
    return (
        <>
        <header className="headerYaoWrapp head_back_d">
            <div className="container">
                <div className="yao_heading">Objetivos</div>
            </div>
        </header>


        <section className="mt-5 mb-3">
          <div className="container">
            <div className="row">


                <div className="col-lg-12">
                    <Card className="bord_yao">
                        <Card.Body>
                            <Card.Text>
                            <span className="text_objetivo">Físico:</span> Incrementar la fuerza, la resistencia, la elasticidad, la velocidad y la coordinación del cuerpo, además de que enseña movimientos de defensa personal.
                            <br /><br />
                            <span className="text_objetivo">Mental:</span> Enseñar la filosofía y la historia de Anahuak. Aprendemos teoría e historia del arte de la guerra, anatomía, astronomía, medicina alternativa. Estrategias para triunfar en la vida.

                            <br /><br />
                            <span className="text_objetivo">Espiritual:</span> Inculca valores de honor, lealtad, austeridad, y respeto, a través de una actitud positiva para el desarrollo de la percepción, del intento, de la energía que nos rodea y del descubrimiento del ser interior.

                            <br /><br />
                            <span className="text_objetivo">Colectivo:</span> Rescatar el corazón y el rostro guerrero de Mexihko para reconstruir la identidad, fomentar la tolerancia, el respeto, los códigos guerreros y la unidad.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>

                <div className="col-lg-12 mx-auto">
                    <img src={yaoImgM03} alt="imagen yaom3" className="img-fluid mx-auto d-block mt-5" />
                </div>


            </div>
          </div>
        </section>


        </>
    )
}