import Card from 'react-bootstrap/Card';
import yaoImgA from '../assets/img/yaoImg05.png';
import yaoImgB from '../assets/img/yaoImg06.png';

export const Mito = () => {
    return (
        <>
        <header className="headerYaoWrapp head_back_a">
            <div className="container">
                <div className="yao_heading">Mito</div>
            </div>
        </header>


        <section className="mt-5">
          <div className="container">
            <div className="row">

            <div className="col-lg-6">
                 <img src={yaoImgA} alt="imagen yao2" className="img-fluid mx-auto d-block" />
            </div>

            <div className="col-lg-6">
                <Card className="bord_yao">
                    <Card.Body>
                        <Card.Text>
                        Un mito es la narración extraordinaria acerca de la creación, que determina las relaciones interpersonales de la sociedad y es lo que distingue el origen de las culturas y es la diferencia entre ellas, así que el mito –no falacia- de la migración y la salida de los aztecas de Aztlan, por encomienda de <span className="green_text">Huitzilopochtli</span> así como su llegada a México-Tenochtitlan - es el registro de una narración que configuró la unidad y la identidad de los mexicas a partir de un pasado glorioso compartido que les permitió visualizar y contemplar su futuro.
                        </Card.Text>
                    </Card.Body>
                </Card>
           </div>



            <div className="col-lg-6">
                <Card className="bord_yao">
                    <Card.Body>
                        <Card.Text>
                        En esta óptica, la escena primitiva (mítica) determina el significado de las cosas, su apreciación e interpretación decretada por una tradición significante, se instala como un registro arquetípico que se guarda en el expediente de cada cultura en general y en cada individuo en particular, que se identifican con su mito original y actúan en consecuencia.
                        <br /><br />
                        Así que <span className="orange_text">Yaollin</span> recupera el significado nahua de la guerra sagrada, en tanto equilibrio del ser en la experiencia transformadora, tal como lo hace el fuego con la naturaleza, que tiene al sol como El general dador de vida y del perpetuo movimiento, en ritmo con la danza de las constelaciones y el sonido de las estrellas, de la transformación interna a la transmutación de la cultura, su regeneración y gestación
                        </Card.Text>
                    </Card.Body>
                </Card>
           </div>

           <div className="col-lg-6">
                 <img src={yaoImgB} alt="imagen yao2" className="img-fluid mx-auto d-block" />
            </div>

            </div>
          </div>
        </section>


        </>
    )
}