import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item } from 'react-photoswipe-gallery'
import galleryhome_a from '../assets/img/home_gallery/homeg01.jpeg';
import galleryhome_b from '../assets/img/home_gallery/homeg02.jpeg';
import galleryhome_c from '../assets/img/home_gallery/homeg03.jpeg';
import galleryhome_d from '../assets/img/home_gallery/homeg04.jpeg';
import galleryhome_e from '../assets/img/home_gallery/homeg05.jpeg';
import galleryhome_f from '../assets/img/home_gallery/homeg06.jpeg';

export const GalleryHome = () => {
    const smallItemStyles: React.CSSProperties = {
            cursor: 'pointer',
            objectFit: 'cover',
            width: '100%',
            maxHeight: '100%',
          }

    return (
        <>
    <Gallery withCaption>
      <div className='container galle_ho'
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto',
          gridTemplateRows: 'auto',
          gridGap: 30,
        }}
      >
   <div className="row">    
    <div className="col-lg-4 col-md-4 col-sm-6">
        <Item
          original={galleryhome_a}
          thumbnail={galleryhome_a}
          width="800"
          height="600"
          alt="Examen 2011"
          caption="Examen 2011"
        >
          {({ ref, open }) => (
            <img
              style={{ ...smallItemStyles, gridColumnStart: 1 }}
              src={galleryhome_a}
              alt="Examen 2011"
              ref={ref as React.MutableRefObject<HTMLImageElement>}
              onClick={open}
            />
          )}
        </Item>
    </div> 

    <div className="col-lg-4 col-md-4 col-sm-6">
        <Item
            original={galleryhome_b}
            thumbnail={galleryhome_b}
            width="800"
            height="600"
            alt="Exhibición"
            caption="Exhibición"
            >
            {({ ref, open }) => (
                <img
                style={{ ...smallItemStyles, gridColumnStart: 2 }}
                src={galleryhome_b}
                alt="Exhibición"
                ref={ref as React.MutableRefObject<HTMLImageElement>}
                onClick={open}
                />
            )}
        </Item>
    </div> 

    <div className="col-lg-4 col-md-4 col-sm-6">
        <Item
            original={galleryhome_c}
            thumbnail={galleryhome_c}
            width="800"
            height="600"
            alt="Entrenamiento en Naucalli"
            caption="Entrenamiento en Naucalli"
            >
            {({ ref, open }) => (
                <img
                style={{ ...smallItemStyles, gridColumnStart: 3 }}
                src={galleryhome_c}
                alt="Entrenamiento en Naucalli"
                ref={ref as React.MutableRefObject<HTMLImageElement>}
                onClick={open}
                />
            )}
        </Item>
    </div> 

    <div className="col-lg-4 col-md-4 col-sm-6">
        <Item
            original={galleryhome_d}
            thumbnail={galleryhome_d}
            width="800"
            height="600"
            alt="Formas"
            caption="Formas"
            >
            {({ ref, open }) => (
                <img
                style={{ ...smallItemStyles, gridColumnStart: 3 }}
                src={galleryhome_d}
                alt="Formas"
                ref={ref as React.MutableRefObject<HTMLImageElement>}
                onClick={open}
                />
            )}
        </Item>
    </div> 

    <div className="col-lg-4 col-md-4 col-sm-6">
        <Item
            original={galleryhome_e}
            thumbnail={galleryhome_e}
            width="800"
            height="600"
            alt="Ceremonía"
            caption="Ceremonía"
            >
            {({ ref, open }) => (
                <img
                style={{ ...smallItemStyles, gridColumnStart: 3 }}
                src={galleryhome_e}
                alt="Ceremonía"
                ref={ref as React.MutableRefObject<HTMLImageElement>}
                onClick={open}
                />
            )}
        </Item>
    </div> 

    <div className="col-lg-4 col-md-4 col-sm-6">
        <Item
            original={galleryhome_f}
            thumbnail={galleryhome_f}
            width="800"
            height="600"
            alt="Danza"
            caption="Danza"
            >
            {({ ref, open }) => (
                <img
                style={{ ...smallItemStyles, gridColumnStart: 3 }}
                src={galleryhome_f}
                alt="Danza"
                ref={ref as React.MutableRefObject<HTMLImageElement>}
                onClick={open}
                />
            )}
        </Item>
    </div> 


     </div> 

      </div>
    </Gallery>

        </>
    )
}