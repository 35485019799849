import { Navbar, Nav, Container } from "react-bootstrap";
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../assets/img/logo.png';

import { useState } from "react";
import { LoginRegister } from "./LoginRegister";

export const Menu = () => {

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

    return (
    <Navbar expand="lg" className="nav_yao border-bottom" fixed="top">
      <Container>
        <Navbar.Brand href="/">
            <img src={logo} alt="Logo" className="img-fluid" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="m-auto"
       
          >
            <Nav.Link href="/">Inicio</Nav.Link>
            
            <NavDropdown title="Historia" id="navbarScrollingDropdown">
              <NavDropdown.Item href="/origenes">Orígenes</NavDropdown.Item>
              <NavDropdown.Item href="/antecedentes">Antecedentes</NavDropdown.Item>
              <NavDropdown.Item href="/mito">Mito</NavDropdown.Item>
              <NavDropdown.Item href="/queesyaollin">¿Qué es Yaollin?</NavDropdown.Item>
              <NavDropdown.Item href="/etimologia">Etimología</NavDropdown.Item>
            </NavDropdown>
   
            <NavDropdown title="Estructura" id="navbarScrollingDropdownb">
              <NavDropdown.Item href="/sistema">Sistema</NavDropdown.Item>
              <NavDropdown.Item href="/grados">Grados</NavDropdown.Item>
            </NavDropdown>

            <Nav.Link href="/escuelas">Escuelas</Nav.Link>
            
            <NavDropdown title="Multimedia" id="navbarScrollingDropdownc">
              <NavDropdown.Item href="/galeria">Galería</NavDropdown.Item>
            </NavDropdown>

            <Nav.Link href="/biblioteca">Biblioteca</Nav.Link>

            <NavDropdown title="Conócenos" id="navbarScrollingDropdownd">
              <NavDropdown.Item href="/mision">Misión</NavDropdown.Item>
              <NavDropdown.Item href="/vision">Visión</NavDropdown.Item>
              <NavDropdown.Item href="/objetivos">Objetivos</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/contacto">Contacto</NavDropdown.Item>
            </NavDropdown>


            {/*
            <Nav.Link onClick={handleShow}>
              <LoginRegister />
            </Nav.Link>
          */}
            

 

          </Nav>

        </Navbar.Collapse>
      </Container>
    </Navbar>

    )
}