// import { getSecInstance, postSecInstance, putInstance, putSecInstance } from "./axioConfig"
import { getInstance, postInstance, patchInstance, deleteInstance } from "./axioConfig"
import API from "./constants"
const TOKEN = process.env.REACT_APP_SEPOMEX_TOKEN

// GALLERY
export const fetchGallery = () => { return getInstance(`${API.getGalleryImage}`) }

// CONTACT
export const fetchSendContactForm = (input) => { return postInstance(`${API.sendContactForm}`, input) }

// SEPOMEX
export const fetchGetCP = (cp) => { return getInstance(`${API.getCP}/${cp}?token=${TOKEN}`) }
export const fetchGetInfoCP = (cp) => { return getInstance(`${API.getInfoCP}/${cp}?token=${TOKEN}`) }
export const fetchGetColCP = (cp) => { return getInstance(`${API.getColCP}/${cp}?token=${TOKEN}`) }

