import React from "react";
import TextTransition, { presets } from "react-text-transition";

export const WelcomeTitle = () => {


    const TEXTS = [
        "Bienvenidos",
        "Mah Cualli Xihualacan"
      ];

      const [index, setIndex] = React.useState(0);
    
      React.useEffect(() => {
        const intervalId = setInterval(() =>
          setIndex(index => index + 1),
          4000
        );
        return () => clearTimeout(intervalId);
      }, []);

    return (


   
   <section id="testimonial">
      <div className="container">
        <div className="row">
          <div className="col-md-12 mx-auto d-block">
            <div className="st-testimonials">
              <div className="item text-center">
                <TextTransition springConfig={presets.molasses} className="thome">
                      {TEXTS[index % TEXTS.length]}
                </TextTransition>
              </div>
            </div>
          </div>
        </div>
      </div>
  </section>



    )
}