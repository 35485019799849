import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item } from 'react-photoswipe-gallery'
import { ClipLoader } from 'react-spinners'
import ListGroup from 'react-bootstrap/ListGroup';
import { CSSProperties, useEffect, useState } from 'react';
import { debug } from '../utilities/helper';
import { EnumGalleryItems } from '../interfaces/galleryItems';
import { fetchGallery } from '../config/fetchUtil'

export const GalleryYao = () => {
    const override: CSSProperties = {
        display: "block",
        margin: "0 auto",
        borderColor: "red",
    };
    const [images, setImages] = useState<EnumGalleryItems>([]);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState('todos');
    const [color, setColor] = useState("#ffffff");

    const [loadMessage, setLoadMessage] = useState('')
    const smallItemStyles: React.CSSProperties = {
        cursor: 'pointer',
        objectFit: 'cover',
        width: '100%',
        maxHeight: '100%',
    }

    useEffect(() => {
        debug("nerv");
        GetImage();
    }, []);

    const GetImage = () => {
        setLoading(true);
        setLoadMessage(`Obteniendo imagenes...`)
        fetchGallery().then((response) => {
            console.log(response)
            setImages(response)
        }).catch((error) => {
            debug(error)
        }).finally(() => {
            setLoading(false)
        })

    }

    const DoFilter = (filter: any) => {
        console.log(filter);
        GetImage();
        setFilter(filter);
    }
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="filter_list">
                            <ListGroup horizontal defaultActiveKey="#link1">
                                <ListGroup.Item id="todos" className={filter === "todos" ? "active_gal" : ""} action onClick={e => DoFilter(e.currentTarget.id)}>
                                    Todas
                                </ListGroup.Item>
                                <ListGroup.Item id="ilustracion" className={filter === "ilustracion" ? "active_gal" : ""} action onClick={e => DoFilter(e.currentTarget.id)}>
                                    Ilustraciones
                                </ListGroup.Item>
                                <ListGroup.Item id="foto" className={filter === "foto" ? "active_gal" : ""} action onClick={e => DoFilter(e.currentTarget.id)}>
                                    Fotos
                                </ListGroup.Item>
                            </ListGroup>
                        </div>
                    </div>
                </div>
            </div>

            <Gallery withCaption>
                <div className='container galle_ho'
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'auto',
                        gridTemplateRows: 'auto',
                        gridGap: 30,
                    }}
                >
                    <div className="row">
                        {images?.length > 0 ? images.map((image, index) => {
                            if (image.category === filter || filter === "todos") {
                                return (
                                    <div
                                        key={index}
                                        className="col-lg-3 col-md-3 col-sm-6">
                                        <Item
                                            original={image.src}
                                            thumbnail={image.src}
                                            width="1080"
                                            height="720"
                                            alt={image.name}
                                            caption={image.alt}
                                        >
                                            {({ ref, open }) => (
                                                <img
                                                    style={{ ...smallItemStyles, gridColumnStart: 3 }}
                                                    src={image.src}
                                                    alt="Batallas"
                                                    ref={ref as React.MutableRefObject<HTMLImageElement>}
                                                    onClick={open}
                                                />
                                            )}
                                        </Item>
                                    </div>
                                )
                            }

                        }) :
                            (<ClipLoader color={color} loading={loading} cssOverride={override} size={150} />)
                        }
                    </div>

                </div>
            </Gallery>

        </>

    )
}