import Card from 'react-bootstrap/Card';
import yaoImgA from '../assets/img/yaoImg07.jpg';

export const QueEsYaollin = () => {
    return (
        <>
        <header className="headerYaoWrapp head_back_a">
            <div className="container">
                <div className="yao_heading">¿Qué es Yaollin?</div>
            </div>
        </header>


        <section className="mt-5">
          <div className="container">
            <div className="row">

            <div className="col-lg-12">
                <Card className="bord_yao">
                    <Card.Body>
                        <Card.Text>
                        Es un sistema de arte marcial con raíces toltecas y mexicas, de los primeros adopta su filosofía, su ética, las posturas bioenergéticas y los movimientos psicoenergéticos. De los mexicas adquiere el idioma, la mística guerrera, sus formas artísticas, sus ceremonias y sus graduaciones rituales.
                        <br /><br />
                        La xochiyaotl, la guerra sagrada o guerra florida, es el vestigio contundente de la existencia de un arte guerrero tolteca, rescatado y preservado por sus descendientes, los mexicas, a través de los siglos.
                        <br /><br />
                        Los guerreros jaguar y águila gobernaron Tula, Chichen Itza y Tenochtitlan a través del arte de la guerra ritual: Xochiyaotl.
                        </Card.Text>
                    </Card.Body>
                </Card>
           </div>

           <div className="mt-4"></div>

           <div className="col-lg-12">
                 <img src={yaoImgA} alt="imagen yao3" className="img-fluid mx-auto d-block mt-1 w-100" />
           </div>

            </div>
          </div>
        </section>


        </>
    )
}