import Card from 'react-bootstrap/Card';
import yaoImgA from '../assets/img/yaoImg03.jpeg';
import yaoImgB from '../assets/img/yaoImg04.jpeg';

export const Antecedentes = () => {
    return (
        <>
        <header className="headerYaoWrapp head_back_a">
            <div className="container">
                <div className="yao_heading">Antecedentes</div>
            </div>
        </header>


        <section className="mt-5">
          <div className="container">
            <div className="row">

            <div className="col-lg-12">
                <Card className="bord_yao">
                    <Card.Body>
                        <Card.Text>
                        
                        El año ze akatl (840) estuvo marcado por el nacimiento del tolteca entre los toltecas Ze Akatl Topiltzin Ketzalkoatl, 676 años después, en otro año ze akatl (1519) llegó Hernán Cortés a Tenochtitlan, y con ello habrían transcurrido 13 ciclos de fuego nuevo, es decir de 52 años cada uno,(13x52=676) desde entonces. A partir del año 1519 iniciaría un periodo de nueve ciclos de fuego nuevo, igualmente de 52 años, para dar un total de 568 años, ciclo que terminaría el 21 de diciembre de 1987, año en el concluiría el “reinado” de los Nueve Señores de la Noche y el inicio del régimen de los Trece Señores del Día. Ese año 1987 también fue un año ze akatl, con lo cual se inició un ciclo de apertura de la consciencia que posteriormente tendría distintos eventos importantes como protagonistas. Casi dos décadas después sucedió el cruce de Venus por el disco solar (Ketzalkoatl entró a la gran hogera) el 8 de junio de 2004, día en que se reconoció por primera vez al <span className="orange_text">Sistema Yaollin</span>, como un sistema de <span className="orange_text">Arte Marcial Mexihka Tolteka</span>.
                        <br /> <br />
                        Esto sucedió gracias a que a partir de 1987 los abuelos herederos y guardianes de las antiguas tradiciones de México comenzaron a abrir sus mesas y adoratorios a representantes civiles que no pertenecían a las familias antiguas que habrían resguardado el conocimiento ancestral nahua. Había llegado el tiempo de terminar con la resistencia y la migración del pueblo mexihka que se habría dispersado desde 1519, pero en 1987 se comenzaría a reunir de nuevo para terminar con la migración histórica y así refundar la cultura en el año ome akatl el 21 de diciembre de 2027, fecha del encendido del fuego nuevo.


                        </Card.Text>
                    </Card.Body>
                </Card>
           </div>   

        <div className="mt-5"></div>

            <div className="col-lg-7">
                <Card className="bord_yao">
                    <Card.Body>
                        <Card.Text>
                        En ese contexto, <span className="orange_text">José Luís Espíndola Chalchikuhatli</span> quien habría nacido en un año ome akatl (1975), comenzó su estudio de artes marciales desde 1980 con apenas cinco años, posteriormente comenzaría su búsqueda del camino del guerrero mexihka hacia la segunda mitad de la década de los 90’s. <br /><br /> Sus primeras incursiones en las tradiciones guerreras fueron a través de un grupo de danza azteka-chchimeka, en donde comprendió que las formas militares mexihkah habían quedado ocultas en la danza y en las estructuras de las mesas de tradición conchera, en muchos de los casos.
                        </Card.Text>
                    </Card.Body>
                </Card>
           </div>

           <div className="col-lg-5">
                 <img src={yaoImgA} alt="imagen yao2" className="img-fluid mx-auto d-block mt-1" />
           </div>

           <div className="col-lg-12">
                <Card className="bord_yao">
                    <Card.Body>
                        <Card.Text>
                        
                        Sin embargo, se dio cuenta de que el sincretismo de estos grupos con el catolicismo los había bloqueado ideológicamente de la posibilidad de romper con el colonialismo y depurar con ello las formas guerreras ancestrales con respecto de la religión y la interpretación del invasor.
                        <br /><br />
                        Lo importante de dicha experiencia es que se había abierto el círculo de su comprensión de las artes marciales a otra esfera en la que la espiritualidad de <span className="orange_text">Chalchikuauhtli</span> se iba a desarrollar a través de la danza y de la <span className="orange_text">Xochiyaoyotl</span> por más de 20 años desde entonces. Desafortunadamente en el camino siempre hay vertientes, el de los fanfarrones y el de los guardianes, en el primer caso encontró a la escuela Xilam de Marisela Ugalde en donde observó grandes carencias filosóficas y técnicas del sistema, pero principalmente descubrió engaños, pues ella sostiene que su sistema de arte marcial es prehispánico, pero esto no es así, de tal modo que, una vez que Chalchikuauhtli alcanzó el grado de maestro armadillo se dio cuenta de la gran farsa de Marisela Ugalde y fue así que retomó el camino del guerrero mexihka, primero en la sierra oaxaqueña de San José del Pacífico, en donde tata Manuel le compartió los principios de la medicina tradicional y posteriormente Chalchikuauhtli aprendería la terapia con obsidianas calientes de Don Fidel en Teotihuakan.


                        </Card.Text>
                    </Card.Body>
                </Card>
           </div> 

           <div className="mt-4"></div>

           <div className="col-lg-12">
                 <img src={yaoImgB} alt="imagen yao3" className="img-fluid mx-auto d-block mt-1 w-100" />
           </div>

           <div className="mt-1"></div>

           <div className="col-lg-12">
                <Card className="bord_yao mb-5">
                    <Card.Body>
                        <Card.Text>
                        
                        En otro escenario Chalchikuahtli iba desarrollando un pensamiento filosófico acerca de la política y de la guerra, el cual ha sido publicado en diferentes artículos y obras colectivas por parte del Posgrado de Derecho de la UNAM. En los mencionados trabajos, José Luís Espíndola ha rescatado los elementos fundamentales de los principios tanto antropológicos como jurídicos de las tradiciones guerreras mexihka toltekah. Con tal cúmulo de experiencias decide sistematizar en el 2002 el conocimiento ancestral del arte de la guerra xochiyaoyotl en una ingeniería de desarrollo personal y humano, el cual tomó por nombre Yaollin, que por primera vez vio la luz pública el 8 de junio de 2004. Para el 8 de agosto de 2008 Yaollin firmaría un convenio con el TEC de Monterrey campus Estado de México, el cual duraría cinco años, tiempo que se practicaría y se desarrollaría el <span className="orange_text">Sistema Yaollin</span>, para con ello, dar paso a la restauración de la <span className="orange_text">Xochiyaoyotl</span>, o guerra florida.


                        </Card.Text>
                    </Card.Body>
                </Card>
           </div> 

            </div>
          </div>
        </section>


        </>
    )
}