import { GalleryYao } from "../components/GalleryYao"

export const Galeria = () => {
    return (
      <>
        <header className="headerYaoWrapp head_back_a">
            <div className="container">
                <div className="yao_heading">Galería</div>
            </div>
        </header>

        <section id="gallery_yao">
           <GalleryYao />
         </section>
        
      </>
    )
}