import Card from 'react-bootstrap/Card';
import yaoImgM01 from '../assets/img/yaoImg09.png';


export const Mision = () => {
    return (
        <>
        <header className="headerYaoWrapp head_back_d">
            <div className="container">
                <div className="yao_heading">Misión</div>
            </div>
        </header>


        <section className="mt-5 mb-3">
          <div className="container">
            <div className="row">


                <div className="col-lg-12">
                    <Card className="bord_yao">
                        <Card.Body>
                            <Card.Text>
                            Impartir una enseñanza de arte marcial mexica-tolteca con amplia calidad filosófica y técnica, que dé los elementos necesarios para una seguridad personal que permita integrar aspectos trascendentales para el desarrollo personal y la evolución de los grupos sociales, fundamentada en los principios de la mística y la tradición de los guerreros águilas y jaguares que gobernaron e hicieron flores bastas civilizaciones del México precuauhtémico.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>

                <div className="col-lg-6 mx-auto">
                    <img src={yaoImgM01} alt="imagen yaom1" className="img-fluid mx-auto d-block mt-5" />
                </div>


            </div>
          </div>
        </section>


        </>
    )
}