import Card from 'react-bootstrap/Card';
import yaoImgM02 from '../assets/img/yaoImg10.png';


export const Vision = () => {
    return (
        <>
        <header className="headerYaoWrapp head_back_d">
            <div className="container">
                <div className="yao_heading">Visión</div>
            </div>
        </header>


        <section className="mt-5 mb-3">
          <div className="container">
            <div className="row">


                <div className="col-lg-12">
                    <Card className="bord_yao">
                        <Card.Body>
                            <Card.Text>
                            La visión de Yaollin es la reconciliación con el México precuauhtémico, a partir de su reinterpretación y de su transmisión con el apoyo de la tradición etnolingüística nahua, a través de la enseñanza de sus mitos, su lengua, su arte y su cosmovisión guerrera, para proyectar el futuro de México desde la raíz con la mira de restaurar la dignidad y la seguridad del ser en la naturaleza.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>

                <div className="col-lg-12 mx-auto">
                    <img src={yaoImgM02} alt="imagen yaom2" className="img-fluid mx-auto d-block mt-5" />
                </div>


            </div>
          </div>
        </section>


        </>
    )
}