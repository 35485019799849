import Card from 'react-bootstrap/Card';
import yaoImgA from '../assets/img/yaoImg02.png';


export const Origenes = () => {
    return (
        <>
        <header className="headerYaoWrapp head_back_a">
            <div className="container">
                <div className="yao_heading">Orígenes</div>
            </div>
        </header>


        <section className="mt-5 mb-3">
          <div className="container">
            <div className="row">

            <div className="col-lg-6">
                 <img src={yaoImgA} alt="imagen yao2" className="img-fluid mx-auto d-block" />
            </div>

            <div className="col-lg-6">
                <Card className="bord_yao">
                    <Card.Body>
                        <Card.Text>
                        El origen, en tanto principio, es conceptualizado por la filosofía como arkhé, así que si hay que identificar una escena originaria de la tradición mexica-tolteca ésta se encuentra en la concepción de Huehueteotl –de huehuetl: viejo y de teotl: energía creativa- a quien se le identifica con el fuego, así que es este el primer elemento.
                        <br /><br />
                        Esta cosmogonía ubicó al sol como un ser con antecedentes arcaicos en la gestación y en la formación del universo, a partir de la concepción de equilibrio al que los mexicas-tenochcas llamaron atlachinolli y es precisamente el signo (palabra) que nace del pico del águila posada en el nopal, esculpida en el Teocalli de la guerra sagrada del Museo de Antropología.
                        </Card.Text>
                    </Card.Body>
                </Card>
           </div>

            </div>
          </div>
        </section>


        </>
    )
}