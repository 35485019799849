const CORS = process.env.REACT_APP_CORS
const BASE_URL = `${CORS}${process.env.REACT_APP_NODE_ENV === 'production' ? process.env.REACT_APP_BASE_URL : process.env.PUBLIC_URL}`
const STATIC_URL = `${process.env.PUBLIC_URL}`
const SEPOMEX = process.env.REACT_APP_SEPOMEX

module.exports = Object.freeze({
    // CONTENT STATIC JSON
    getGalleryImage: `${STATIC_URL}/assets/gallery.json`,

    // CONTACT FORM
    sendContactForm: `${BASE_URL}/api/v1/contact`,

    // SEPOMEX
    getCP: `${SEPOMEX}/query/search_cp`,
    getInfoCP: `${SEPOMEX}/query/info_cp`,
    getColCP: `${SEPOMEX}/query/get_colonia_por_cp`,
})