import Card from 'react-bootstrap/Card';
import yaoImgA from '../assets/img/yaoImg08.jpg';

export const Etimologia = () => {
    return (
        <>
        <header className="headerYaoWrapp head_back_a">
            <div className="container">
                <div className="yao_heading">¿Qué quiere decir Yaollin?</div>
            </div>
        </header>


        <section className="mt-5">
          <div className="container">
            <div className="row">

            <div className="col-lg-12">
                <Card className="bord_yao">
                    <Card.Body>
                        <Card.Text>
                        Es una palabra náhuatl compuesta por dos raíces: <span className="orange_text">yaotl</span> (enemistad o guerra) y <span className="orange_text">ollin</span> (movimiento) se traduce como: Movimiento Guerrero.
                        </Card.Text>
                    </Card.Body>
                </Card>
           </div>

           <div className="mt-4"></div>

           <div className="col-lg-12">
                 <img src={yaoImgA} alt="imagen yao3" className="img-fluid mx-auto d-block mt-1 w-100" />
           </div>

            </div>
          </div>
        </section>


        </>
    )
}