import logo_footer from '../assets/img/logofooter.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faYoutube } from "@fortawesome/free-brands-svg-icons"

export const Footer = () => {
    

    return (

    <footer className="footer mt-auto py-3">
      <div className='container'>
        <div className='row'>
       <p className="col-md-4 mb-0 mt-2 text-muted">Copyright © 2023 - Creado por <a href="https://www.ltte.com.mx" target="_blank" rel="noreferrer" className="link_footer text-muted"> LTTE Web Development</a></p>
       
        <div className="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto">
            <img src={logo_footer} alt="Logo" />

        </div>

            <ul className="nav col-md-3">
                <li className="nav-item"><a href="https://www.facebook.com/YaollinMexico" target="_blank" rel="noreferrer" className="nav-link px-2"> <FontAwesomeIcon icon={faFacebook} className="icon_footer" /></a></li>
                <li className="nav-item"><a href="https://twitter.com/yaollin" target="_blank" rel="noreferrer" className="nav-link px-2"><FontAwesomeIcon icon={faTwitter} className="icon_footer" /></a></li>
                <li className="nav-item"><a href="https://www.youtube.com/channel/UCa5VoFKL3gYcEUsKl1QpPFA" target="_blank" rel="noreferrer" className="nav-link px-2"><FontAwesomeIcon icon={faYoutube} className="icon_footer" /></a></li>
            </ul>
        </div>
        </div>
     </footer>




    )
}