import { Routes, Route } from 'react-router-dom';
import './App.scss'
import './assets/css/scss/style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Menu } from './components/Menu';
import { Home } from './pages/Home';
import { NotFound } from './pages/NotFound';
import { Origenes } from './pages/Origenes';
import { Antecedentes } from './pages/Antecedentes';
import { Mito } from './pages/Mito';
import { QueEsYaollin } from './pages/QueEsYaollin';
import { Etimologia } from './pages/Etimologia';
import { Sistema } from './pages/Sistema';
import { Grados } from './pages/Grados';
import { Escuelas } from './pages/Escuelas';
import { Galeria } from './pages/Galeria';
import { Biblioteca } from './pages/Biblioteca';
import { Mision } from './pages/Mision';
import { Vision } from './pages/Vision';
import { Objetivos } from './pages/Objetivos';
import { Contacto } from './pages/Contacto';
import { Footer } from './components/Footer';


function App() {
  return (
    <>
      <Menu />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='origenes' element={<Origenes />} />
        <Route path='antecedentes' element={<Antecedentes />} />
        <Route path='mito' element={<Mito />} />
        <Route path='queesyaollin' element={<QueEsYaollin />} />
        <Route path='etimologia' element={<Etimologia />} />
        <Route path='sistema' element={<Sistema />} />
        <Route path='grados' element={<Grados />} />
        <Route path='escuelas' element={<Escuelas />} />
        <Route path='galeria' element={<Galeria />} />
        <Route path='biblioteca' element={<Biblioteca />} />
        <Route path='mision' element={<Mision />} />
        <Route path='vision' element={<Vision />} />
        <Route path='objetivos' element={<Objetivos />} />
        <Route path='contacto' element={<Contacto />} />
        <Route path='*' element={<NotFound />} />
      </Routes>

      <Footer />
    </>
  );
}

export default App;

