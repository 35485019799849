import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';

import { Slider } from '../components/Slider';
import { GalleryHome } from '../components/GalleryHome';
import { WelcomeTitle } from '../components/WelcomeTitle';
import CalendarHome from '../components/CalendarHome';

import yaoImgA from '../assets/img/yaoImg01.png';
import { CarouselCards } from '../components/CarouselCards';
import { Events } from '../components/Events';


export const Home = () => {

    return (
        <>

    <Slider />

      <section id="homeParallax">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h1>¿Qué es yaollin?</h1>
              </div>
            </div>
            <div className="col-lg-6 descrip_text">
              <p>Es un sistema de arte marcial con raíces toltecas y mexicas, de los primeros adopta su filosofía, su ética, las posturas bioenergéticas y los movimientos psicoenergéticos.
                <br /><br />
                De los mexicas adquiere el idioma, la mística guerrera, sus formas artísticas, sus ceremonias y sus graduaciones rituales.</p>
            </div>
            <div className="col-lg-6 descrip_text">
                 <img src={yaoImgA} alt="imagen yao1" className='img-fluid' />
            </div>
          </div>
        </div>
      </section>

     <section id="galleryhome">
       <GalleryHome />
     </section>
     
     <WelcomeTitle />


     <section id="videohome">
        <div className="container">
          <div className="row">

            <div className="col-lg-6">

              <h2>Un <span>arte milenario</span> Mexihka <br /> a tu alcance</h2>
         
              <p>
               En yaollin el desarrollo del estudiante será físico, mental y espiritual logrando canalizar su energia interna en todos los aspectos de su vida gracias al conocimiento.
              </p>

            {/*
               <Button variant="primary" className="btn_unete" size="lg">
                  Únete Ahora
                </Button>{' '}
            */}
               
                <Button href="sistema" variant="secondary" className="btn_unete" size="lg">
                  Conoce más
                </Button>

            </div>
            <div className="col-lg-6">
            <div className="video_yao">
                <iframe
                  width="100%"
                  height="350px"
                  src={'https://www.youtube.com/embed/yVdp3e4bIAo?controls=0'}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Yaollin"
                />
              </div>
            </div>
          </div>
        </div>
      </section>


 {/*
      <section id="testimonials">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              
              <h3>Testimoniales</h3>
               
                <CarouselCards />
             
            </div>
          </div>
       </div>
    </section>
    */}


      <section id="calendarhome">
        <div className="container">
            <div className="row">

              <div className="col-lg-12">
                  <h3>Próximos Eventos</h3>
              </div>

             
              <Events />
            


              {/*
              <div className="col-lg-12">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Calendario de Eventos </Accordion.Header>
                  <Accordion.Body>
                         <CalendarHome />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            
              </div> 
              */}
                
            </div>
        </div>
      </section>
   
      
        </>
    )
}