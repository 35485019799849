import { Carousel } from "react-bootstrap";
import slider_a from '../assets/img/slider/slider_1.jpg';
import slider_b from '../assets/img/slider/slider_2.jpg';
import slider_c from '../assets/img/slider/slider_3.jpg';

export const Slider = () => {
    return (

    <Carousel className="carousel_home">
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={slider_a}
            alt="Slider 1"
          />
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src={slider_b}
            alt="Slider 2"
          />
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src={slider_c}
            alt="Slider 3"
          />
        </Carousel.Item>
    </Carousel>
   

    )
}