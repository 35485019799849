import Card from 'react-bootstrap/Card';
import { Carousel } from "react-bootstrap";
import s_a from '../assets/img/escuelas/s1.jpg';
import s_b from '../assets/img/escuelas/s2.jpg';
import s_c from '../assets/img/escuelas/s3.jpg';
import s_d from '../assets/img/escuelas/s4.jpg';

export const Escuelas = () => {
    return (
        <>
        <header className="headerYaoWrapp head_back_c">
            <div className="container">
                <div className="yao_heading">Escuelas</div>
            </div>
        </header>


        <section className="mt-5 mb-3">
          <div className="container">
           
            <Card>
              <Card.Body>
                <div className="row">
                    <div className="col-lg-6">

                    <Carousel>
                        <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={s_a}
                            alt="slider1"
                        />
                        </Carousel.Item>

                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={s_b}
                                alt="slider2"
                            />
                        </Carousel.Item>

                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={s_c}
                                alt="slider3"
                            />
                        </Carousel.Item>

                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={s_d}
                                alt="slider4"
                            />
                        </Carousel.Item>

                    </Carousel>


                    <Card.Text className="escuelas_text">
                        <span>Tonalkalli</span>
                        <strong>Dirección:</strong> Av. Aquiles Serdán 1273, Tierra Nueva, Azcapotzalco, 
                            02130 Ciudad de México, CDMX.
                            <br /> 

                        <strong>Horarios:</strong> Martes - 18:00pm y Domingos - 10:00am a 12:00pm
                    </Card.Text>

                    </div>

                    <div className="col-lg-6">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3760.943101941302!2d-99.20367850000001!3d19.501083599999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d2031c91a52bef%3A0x5a76baeb488a5153!2sTONALKALLI!5e0!3m2!1sen!2smx!4v1656525798210!5m2!1sen!2smx" title="frameMap" width="100%" height="600" loading="lazy"></iframe>
                    </div>
                </div>
                </Card.Body>
            </Card>


          </div>
        </section>


        </>
    )
}