import Button from 'react-bootstrap/Button';

export const NotFound = () => {
    return (
        <>
        <header id="notfound" className="headerYaoWrapp head_back_d">
            <div className="container">
                <div className="yao_heading">Página no encontrada</div>

                <Button href="/" variant="secondary" className="btn_return_h" size="lg">
                    Regresar a Inicio
                </Button>

            </div>
        </header>


        </>
    )
}