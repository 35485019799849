import Card from 'react-bootstrap/Card';
import yaoImgBia from '../assets/img/icon_b1.png';
import yaoImgBib from '../assets/img/icon_b2.png';
import yaoImgBic from '../assets/img/icon_b3.png';
import yaoImgBid from '../assets/img/icon_b4.png';

export const Biblioteca = () => {
    return (
      <>
        <header className="headerYaoWrapp head_back_e">
            <div className="container">
                <div className="yao_heading">Biblioteca</div>
            </div>
        </header>

        <section className="mt-5 mb-3">
          <div className="container">
            <div className="row">


                <div className="col-lg-6">
                  <Card className="card_bib">
                      <Card.Body>
                      <img src={yaoImgBia} alt="icon" className="img-fluid" />
                          <Card.Title>Filosofía del derecho</Card.Title>
                          <Card.Link href="#">Filosofía, tradición e ideología en el derecho penal mexica tenochca</Card.Link>
                          <Card.Link href="#"> Derecho de guerra mexica tenochca</Card.Link>
                          <Card.Link href="https://www.scribd.com/embeds/54398430/content?start_page=1&view_mode=slideshow&access_key=key-zjv9vbt63791bx3pwq3" target="_blank"> Identidad neoxica</Card.Link>
                          <Card.Link href="#"> Pluralismo jurídico nahua</Card.Link>
                          <Card.Link href="#"> Crítica a la lengua imperial y la fenomenología de la revolución</Card.Link>
                      </Card.Body>
                  </Card>
                </div>

                <div className="col-lg-6">
                  <Card className="card_bib">
                      <Card.Body>
                      <img src={yaoImgBib} alt="icon" className="img-fluid" />
                          <Card.Title>Filosofía Político Militar</Card.Title>
                          <Card.Link href="#">Del conflicto cultural a la guerra en México en 2010</Card.Link>
                          <Card.Link href="https://www.scribd.com/embeds/62544844/content?start_page=1&view_mode=slideshow&access_key=key-larb167phy4e7wp997h" target="_blank"> El templo zapatista de la revolución</Card.Link>
                          <Card.Link href="#"> Guerra, sacrificios y significados</Card.Link>
                          <Card.Link href="https://www.scribd.com/embeds/54398419/content?start_page=1&view_mode=slideshow&access_key=key-10xnebuuq4k8cbw5gwwd" target="_blank"> El telpochkalli: pilar de la educación mexihkca tenochkaa</Card.Link>
                          <Card.Link href="#"> &nbsp;</Card.Link>
                      </Card.Body>
                  </Card>
                </div>

                <div className="col-lg-6">
                  <Card className="card_bib">
                      <Card.Body>
                      <img src={yaoImgBic} alt="icon" className="img-fluid" />
                          <Card.Title>Temas diversos</Card.Title>
                          <Card.Link href="https://www.scribd.com/embeds/54398417/content?start_page=1&view_mode=slideshow&access_key=key-12276wn4s26agdqe62oj" target="_blank">El Cacao</Card.Link>
                          <Card.Link href="https://www.scribd.com/embeds/62544887/content?start_page=1&view_mode=slideshow&access_key=key-2enjg73t8zbf4qux0lo" target="_blank"> Las competencias femeninas nahuas</Card.Link>
                          <Card.Link href="#"> De leer a Nietzsche para entender a Moctezuma II</Card.Link>
                      </Card.Body>
                  </Card>
                </div>

                <div className="col-lg-6">
                  <Card className="card_bib">
                      <Card.Body>
                      <img src={yaoImgBid} alt="icon" className="img-fluid" />
                          <Card.Title>Psicología</Card.Title>
                          <Card.Link href="https://www.scribd.com/embeds/69339534/content?start_page=1&view_mode=slideshow&access_key=key-18zt2irq609r3j3sxqol" target="_blank">La palabra y el inconsciente en el México prekuauhtémico</Card.Link>
                          <Card.Link href="https://www.scribd.com/embeds/54398413/content?start_page=1&view_mode=slideshow&access_key=key-2fzfxjsjmjmqpshd478e" target="_blank">Arte, filosofía y terapia ritual</Card.Link>
                          <Card.Link href="#"> &nbsp;</Card.Link>
                      </Card.Body>
                  </Card>
                </div>


            </div>
          </div>
        </section>
        
      </>
    )
}