import yaoImgA from '../assets/img/sistema01.png';
import yaoImgB from '../assets/img/sistema02.png';
import yaoImgC from '../assets/img/sistema03.png';
import yaoImgD from '../assets/img/sistema04.png';

export const Sistema = () => {
    return (
        <>
        <header className="headerYaoWrapp head_back_b">
            <div className="container">
                <div className="yao_heading">Sistema</div>
            </div>
        </header>


        <section id="sistema" className="mt-5">
          <div className="container">
            <div className="row">

                <div className="col-lg-12">
                        <h2>Nuestro Sistema</h2>
                        <p>Cada nivel de Yaollin se compone por cuatro formas ("katas") vinculadas con los cuatro elementos:</p>
                </div>

                <div className="col-xl-6 col-md-6">
                    <div className="sistem_c">
                        <img src={yaoImgA} className="img-fluid mx-auto d-block" alt="Fuego" />
                        <h5>Forma de Fuego</h5>
                        <p>Es una combinación de técnicas (bloqueo, golpeo, pateo, agarres, derribes, <br />inmovilizaciones, etc.) Que se entrenan como aplicaciones tácticas de combate.</p>
                    </div>
                    </div>
                    <div className="col-xl-6 col-md-6">
                    <div className="sistem_c">
                        <img src={yaoImgB} className="img-fluid mx-auto d-block" alt="Agua" />
                        <h5>Forma de Agua</h5>
                        <p>Actúa desde la mente, implicando técnicas suaves y fluidas</p>
                    </div>
                    </div>
                    <div className="col-xl-6 col-md-6">
                    <div className="sistem_c">
                        <img src={yaoImgC} className="img-fluid mx-auto d-block" alt="Tierra" />
                        <h5>Forma de Tierra</h5>
                        <p>La filosofía es la base que contiene a nuestro movimiento físico</p>
                    </div>
                    </div>
                    <div className="col-xl-6 col-md-6">
                    <div className="sistem_c">
                        <img src={yaoImgD} className="img-fluid mx-auto d-block" alt="Viento" />
                        <h5>Forma de Viento</h5>
                        <p>Es la ciencia de la respiración, del Ihiyotl, el aliento de la vida, <br />que a través de técnicas especializadas incrementa nuestro estado de conciencia.</p>
                    </div>
                    </div>


            </div>
          </div>
        </section>


        </>
    )
}