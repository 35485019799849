import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import event1 from '../assets/img/eventos/events_next.jpg';

export const Events = () => {


    return (
    <>

    <div className="col-lg-12 mb-4">
                <Card className="event_card_b">
                    <Card.Img variant="top" src={event1} />
             
        
                </Card>
     </div>

    {/*
        <div className="col-lg-4 mb-4">
                <Card className="event_card_b">
                    <Card.Img variant="top" src={event1} />
             
                    <Card.Text className="text_overlay_ev">20/Octubre/2022</Card.Text>
                 
                    <Card.Body className="events_body">

                        <Card.Title>Exhibición de Yaollin</Card.Title>
                        <Card.Subtitle className="mb-3">Hora: 12:00 - 2:00pm</Card.Subtitle>
                        <Card.Text className="events_body_b">
                        Lugar: Tonalkalli - Av. Aquiles Serdán 1273, Tierra Nueva, Azcapotzalco, 02130
                        </Card.Text>
                        <Button href="https://goo.gl/maps/gAEWRFtjoSLwKxzb6" variant="primary" target="_blank">Ver mapa</Button>
                    </Card.Body>
                </Card>
        </div>

        <div className="col-lg-4 mb-4">
                <Card className="event_card_b">
                    <Card.Img variant="top" src={event2} />
             
                    <Card.Text className="text_overlay_ev">24/Octubre/2022</Card.Text>
                 
                    <Card.Body className="events_body">

                        <Card.Title>Danza de Fuego</Card.Title>
                        <Card.Subtitle className="mb-3">Hora: 10:00 - 12:00pm</Card.Subtitle>
                        <Card.Text className="events_body_b">
                        Lugar: Tonalkalli - Av. Aquiles Serdán 1273, Tierra Nueva, Azcapotzalco, 02130
                        </Card.Text>
                        <Button href="https://goo.gl/maps/gAEWRFtjoSLwKxzb6" variant="primary" target="_blank">Ver mapa</Button>
                    </Card.Body>
                </Card>
        </div>
        */}
    </>
    )
}